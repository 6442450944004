import Disciplina from "@/interfaces/Disciplina";

const DisciplinaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "descricao",
    align: "left",
    label: "Nome",
    field: "descricao",
    sortable: true,
  },
  {
    name: "resumo",
    align: "left",
    label: "Resumo",
    field: "resumo",
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: Disciplina) => row.created_at,
    sortable: true,
  },
];

export default DisciplinaColumn;
