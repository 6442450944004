
import DisciplinaService from "@/services/admin/DisciplinaService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, defineComponent, onMounted } from "vue";
import { requiredField } from "@/util/form-rules";
import Disciplina from "@/interfaces/Disciplina";
import Assunto from "@/interfaces/Assunto";
import FormLoading from "../../layout/FormLoading.vue";
import Divisao from "@/interfaces/Divisao";
import BaseService from "@/services/admin/BaseService";
import store from "@/store";

interface Form {
  descricao: string;
  resumo: string;
  cor: string | null;
  icone: string | null;
  assuntos: Assunto[];
  divisao_id: string;
}

const baseForm: Form = {
  descricao: "",
  resumo: "",
  cor: "",
  icone: "",
  divisao_id: "",
  assuntos: [],
};

export default defineComponent({
  components: { FormLoading },
  setup() {
    const $q = useQuasar();
    const show = ref(false);
    const form = ref<Form>();
    const disciplina = ref<Disciplina>();
    const divisoes = ref<Divisao[]>([]);
    const open = (disciplinaData: Disciplina | null) => {
      if (disciplinaData) {
        disciplina.value = JSON.parse(JSON.stringify(disciplinaData));
        if (disciplina.value?.id) {
          form.value = {
            descricao: disciplina.value.descricao,
            resumo: disciplina.value.resumo,
            cor: disciplina.value.cor ?? "",
            icone: disciplina.value.icone ?? "",
            divisao_id: disciplina.value.divisao.id ?? "",
            assuntos: disciplina.value.assuntos ?? [],
          };
        } else {
          form.value = {
            descricao: "",
            resumo: "",
            cor: "",
            icone: "",
            divisao_id: "",
            assuntos: [],
          };
          if (store.getters.divisaoId) {
            form.value.divisao_id = store.getters.divisaoId;
          }
        }
      }
      show.value = true;
    };

    const getDivisoes = (): void => {
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
        });
    };

    onMounted(() => {
      getDivisoes();
    });

    return {
      show,
      open,
      form,
      disciplina,
      requiredField,
      disciplinas: ref<Disciplina[]>([]),
      loading: ref<boolean>(false),
      showNotify(opts: any) {
        $q.notify(opts);
      },
      divisoes,
      store,
    };
  },

  methods: {
    submit() {
      if (!this.disciplina?.id) {
        this.loading = true;
        DisciplinaService.create(this.form)
          .then(() => {
            this.afterSubmit();
            this.showNotify({
              type: "positive",
              message: "Disciplina salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            this.showNotify({
              type: "negative",
              message: "Erro ao salvar disciplina",
            });
          })
          .finally(() => (this.loading = false));
      } else {
        this.loading = true;
        DisciplinaService.update(this.disciplina.id, this.form)
          .then(() => {
            this.afterSubmit();
            this.showNotify({
              type: "positive",
              message: "Disciplina salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            this.showNotify({
              type: "negative",
              message: "Erro ao salvar disciplina",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    destroy() {
      if (this.disciplina) {
        this.loading = true;
        DisciplinaService.delete(this.disciplina.id)
          .then(() => {
            this.afterSubmit();
            this.showNotify({
              type: "positive",
              message: "Disciplina excluída com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            this.showNotify({
              type: "negative",
              message: "Erro ao excluir disciplina",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    afterSubmit() {
      this.$emit("update");
      this.show = false;
      this.form = baseForm;
    },
  },
});
