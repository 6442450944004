
import { ref, defineComponent } from "vue";
import DisciplinaColumn from "@/util/table-columns/DisciplinaColumn";
import DisciplinaForm from "@/components/admin/disciplinas/DisciplinaForm.vue";
import AulasForm from "@/components/admin/disciplinas/AulasForm.vue";
import AnexosForm from "@/components/admin/disciplinas/anexos/AnexosForm.vue";
import Disciplina from "@/interfaces/Disciplina";
import useList from "@/modules/useList";
import store from "@/store";

export default defineComponent({
  components: { DisciplinaForm, AulasForm, AnexosForm },

  setup() {
    const modalAulasForm = ref();
    const modalAnexosForm = ref();
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      confirmDelete,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/disciplinas/" + store.getters.divisaoId
        : "disciplinas"
      , DisciplinaColumn);

    const openAulasModalForm = (row: Disciplina | null): void => {
      modalAulasForm.value.open(row);
    };

    const openAnexosModalForm = (row: Disciplina | null): void => {
      modalAnexosForm.value.open(row);
    };

    return {
      modalForm,
      openModalForm,
      modalAulasForm,
      openAulasModalForm,
      modalAnexosForm,
      openAnexosModalForm,
      formatData,
      columns,
      rows,
      loading,
      filter,
      list,
      confirmDelete,
    };
  },
});
