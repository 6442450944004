
import Draggable from "vuedraggable";
import DisciplinaService from "@/services/admin/DisciplinaService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import Disciplina from "@/interfaces/Disciplina";
import TipoAula from "@/interfaces/TipoAula";
import Aula from "@/interfaces/Aula";
import Assunto from "@/interfaces/Assunto";

export default defineComponent({
  components: { Draggable },
  setup() {
    const $q = useQuasar();
    const show = ref(false);
    const showAulaDescricao = ref(false);
    const loading = ref<boolean>(false);
    const indexAssuntoEsc = ref<number>();
    const indexAulaEsc = ref<number>();
    const disciplina = ref<Disciplina>();
    const open = (disciplinaData: Disciplina | null) => {
      if (disciplinaData) {
        disciplina.value = JSON.parse(JSON.stringify(disciplinaData));
        show.value = true;
      }
    };

    return {
      show,
      open,
      disciplina,
      requiredField,
      indexAulaEsc,
      indexAssuntoEsc,
      showAulaDescricao,
      disciplinas: ref<Disciplina[]>([]),
      loading,
      showNotify(opts: any) {
        $q.notify(opts);
      },
    };
  },

  methods: {
    submit() {
      if (!this.disciplina?.id) {
        this.loading = true;
        DisciplinaService.create(this.disciplina)
          .then(() => {
            this.afterSubmit();
            this.showNotify({
              type: "positive",
              message: "Disciplina salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            this.showNotify({
              type: "negative",
              message: "Erro ao salvar disciplina",
            });
          })
          .finally(() => (this.loading = false));
      } else {
        this.loading = true;
        DisciplinaService.update(this.disciplina.id, this.disciplina)
          .then(() => {
            this.afterSubmit();
            this.showNotify({
              type: "positive",
              message: "Disciplina salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            this.showNotify({
              type: "negative",
              message: "Erro ao salvar disciplina",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    afterSubmit() {
      this.$emit("update");
      this.show = false;
    },
    addAula(indexAssunto: number) {
      const tipoAulaGravada: TipoAula = {
        created_at: new Date("2021-02-01T14:58:42.000000Z"),
        descricao: "Gravada",
        id: "c1de7d6e-bb59-436c-ba2b-010e28ae0b83",
        nome: "gravada",
        updated_at: new Date("2021-02-01T14:58:42.000000Z"),
      };

      const aula: Aula = {
        created_at: new Date(),
        data_exibicao: new Date(),
        descricao: "",
        id: "",
        live: false,
        ordem: null,
        status: true,
        nome: "",
        resumo: null,
        tipoAula: tipoAulaGravada,
        updated_at: new Date(),
        url: "",
        aula_assistida: null,
        anexos: [],
        assunto: null,
      };

      this.disciplina?.assuntos[indexAssunto].aulas.push(aula);
    },
    addAssunto() {
      const assunto: Assunto = {
        id: "",
        nome: "",
        descricao: "Novo Assunto",
        ordem: null,
        status: true,
        disciplina: null,
        aulas: [],
        anexos: [],
        created_at: new Date(),
        updated_at: new Date(),
      };

      this.disciplina?.assuntos.push(assunto);
    },
    removeAula(indexAssunto: number, indexAula: number) {
      this.disciplina?.assuntos[indexAssunto].aulas.splice(indexAula, 1);
    },
    removeAssunto(indexAssunto: number) {
      this.disciplina?.assuntos.splice(indexAssunto, 1);
    },
    editarResumo(indexAssunto: number, indexAula: number) {
      this.indexAssuntoEsc = indexAssunto;
      this.indexAulaEsc = indexAula;
      this.showAulaDescricao = true;
    },
    subirAula(assunto: Assunto, indexAula: number) {
      const aula: Aula = assunto.aulas[indexAula];
      assunto.aulas[indexAula] = assunto.aulas[indexAula - 1];
      assunto.aulas[indexAula - 1] = aula;
    },
    descerAula(assunto: Assunto, indexAula: number) {
      const aula: Aula = assunto.aulas[indexAula];
      assunto.aulas[indexAula] = assunto.aulas[indexAula + 1];
      assunto.aulas[indexAula + 1] = aula;
    },
  },
});
